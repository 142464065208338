<template>
  <div class="device">
    <div class="sidebar-toggle" @click="toggleShowMenu" v-if="showMenu">
      <i class="fa fa-chevron-left"></i>
    </div>
    <div class="sidebar-toggle" @click="toggleShowMenu" v-if="!showMenu">
      <i class="fa fa-chevron-down"></i>
    </div>
    <div class="sidebar animate-in slide-left" v-show="showMenu">
      <img class="sidebar-logo" src="../assets/Pro-tekt-logo.png">
      <div class="filter-box">
        <b-button pill v-on:click="goBack">Back</b-button>
      </div>
    <device-details></device-details>
    </div>
    <div class="map-container">
      <map-display ref="map"></map-display>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import DeviceDetails from '@/components/device/DeviceDetails'
import MapDisplay from '@/components/helpers/MapDisplay'
import * as ErrorHelper from '@/components/helpers/ErrorHelper'

export default {
  name: 'device-view',
  components: {
    DeviceDetails,
    MapDisplay
  },
  data: function () {
    return {
      viewPort: {
        zoom: 3,
        lat: 0,
        lng: 0
      },
      deviceIMEI: '',
      markers: [],
      routeData: [],
      geolimitData: {},
      geoLimitDataFromMap: {},
      showMenu: true
    }
  },
  created () {
    if (this.$route.params.hasOwnProperty('id')) {
      this.deviceIMEI = this.$route.params.id
    } else {
      ErrorHelper.displayGeneralErrorToast('Uh oh, you\'ve selected an IMEI that isn\'t valid!', 'Invalid Device IMEI')
    }
  },
  methods: {
    goBack: function () {
      this.$router.go(-1)
    },
    viewChange: function (newView) {
      // console.log('ViewChange on Device View Component', newView)
      this.viewPort = newView
    },
    markersChange: function (newMarkers) {
      // console.log('markersChange on Device View Component', newMarkers)
      this.markers = [...newMarkers]
    },
    routerDataChange: function (newRouteData) {
      // console.log('Device View: Route Data Updated')
      this.routeData = newRouteData
    },
    toggleShowMenu: function () {
      this.showMenu = !this.showMenu
    }
  }
}
</script>

<style scoped lang="scss">
  @import '@/variables';
  @import '@/animations';
  .filter-box {
    display: grid;
    margin: 10px;
    padding: 10px;
    grid-auto-columns: 1fr;
    grid-column-gap: 10px;
    grid-row-gap: 8px;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
    -ms-grid-rows: auto;
    grid-template-rows: auto;
    border-radius: 17px;
    background-color: $default-content-background;
  }

  .map-container {
    width: calc(100% - 310px);
    position: absolute;
    top: 0;
    right: 310px;
    height: 100%
  }

  .sidebar-toggle {
    display: none;
    color: white;
    font-size: 2em;
    position: absolute;
    top: 0;
    right: 0;
    width: 60px;
    height: 60px;
  }

  @media screen and (max-width: 600px) {
    .map-container {
      width: calc(100% - 60px);
      right: 60px;
    }
    .sidebar-toggle {
      display: block;
    }
  }

</style>
