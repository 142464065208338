<template>
  <div class="device-details">
    <loading-box v-bind:loading="loading"></loading-box>
<!--    Load Sub-components AFTER we load the device details-->
    <div v-if="device">
      <component :is="deviceComponent" :device="device" :enableEdit="enableEdit"></component>
    </div>
  </div>
</template>

<script>

import * as DataProvider from '@/components/helpers/DataProvider'
import LoadingBox from '@/components/helpers/LoadingBox'
import * as ErrorHelper from '@/components/helpers/ErrorHelper'

export default {
  name: 'device-details',
  components: {
    LoadingBox
  },
  props: { },
  data: function () {
    return {
      loading: true,
      enableEdit: true,
      deviceImei: '',
      showEvents: false,
      device: null,
      deviceComponent: null
    }
  },
  created () {
    if (this.$route.params.hasOwnProperty('id')) {
      this.deviceImei = this.$route.params.id
    } else {
      ErrorHelper.displayGeneralErrorToast('Uh oh, you\'ve selected an IMEI that isn\'t valid!', 'Invalid Device IMEI')
      return
    }
    this.$maps.clearOverlays()
    this.loadData()
  },
  methods: {
    // Refresh/Load All data from the Protekt Server
    loadData: async function () {
      // Check if we have an IMEI specified, otherwise error out
      try {
        // Load Device Details
        let res = await DataProvider.getDeviceByIMEI(this.deviceImei)
        if (!res.success) {
          console.error('Device Details: Error Getting Device Details from Server!')
          ErrorHelper.displayDataErrorToast(res)
          return // abort!
        }
        this.device = res.data
        // Pick a component based on the device type chosen
        this.deviceComponent = this.$deviceService.getDeviceTypeComponent(this.device.device_type)
        // Hack to fix null icon values.
        if (!this.device.icon) {
          this.device.icon = 'Car'
        }
        if (this.device.permissions) {
          if (this.device.permissions.writable || this.device.admin) {
            this.enableEdit = true
          } else {
            this.enableEdit = false
          }
        }
      } catch (err) {
        console.error(err)
        this.$bvToast.toast('An Error Occurred Getting Device Details from the Server.',
          {
            title: 'Server Communication Error',
            variant: 'danger',
            toaster: 'b-toaster-top-center'
          })
      }
      this.loading = false
    }
  },
  watch: {
    '$route.params.id': async function changeDevice (newId) {
      this.loading = true
      this.deviceImei = newId
      this.$maps.clearOverlays()
      this.loadData()
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  @import '../../variables';

</style>
