var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "device" }, [
    _vm.showMenu
      ? _c(
          "div",
          { staticClass: "sidebar-toggle", on: { click: _vm.toggleShowMenu } },
          [_c("i", { staticClass: "fa fa-chevron-left" })]
        )
      : _vm._e(),
    !_vm.showMenu
      ? _c(
          "div",
          { staticClass: "sidebar-toggle", on: { click: _vm.toggleShowMenu } },
          [_c("i", { staticClass: "fa fa-chevron-down" })]
        )
      : _vm._e(),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.showMenu,
            expression: "showMenu"
          }
        ],
        staticClass: "sidebar animate-in slide-left"
      },
      [
        _c("img", {
          staticClass: "sidebar-logo",
          attrs: { src: require("../assets/Pro-tekt-logo.png") }
        }),
        _c(
          "div",
          { staticClass: "filter-box" },
          [
            _c("b-button", { attrs: { pill: "" }, on: { click: _vm.goBack } }, [
              _vm._v("Back")
            ])
          ],
          1
        ),
        _c("device-details")
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "map-container" },
      [_c("map-display", { ref: "map" })],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }